import React from 'react'
import {Box, Divider, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";

const Footer = (props) => {
    return (
        <footer className="s-footer" style={{position: 'relative'}}>
            <Box sx={{py: 1, px:10}}>
                <Grid container>
                    <Grid item lg={4}>
                        <Typography fontWeight={'bold'} sx={{mb:3}}>Our goal</Typography>
                        <Typography>Our server is a dream of the childhood for all of us because of it's purely Conquer
                            Online 1.0 features that we all love. Because of the nostalgia feeling where a little hard
                            work goes a long way. You find the real mean of PK over DragonBall.</Typography>

                        

                    </Grid>
                   
                    <Grid item lg={4}>
                        
                    </Grid>
                    <Grid item lg={4}>
                        <Typography fontWeight={'bold'} sx={{mb:3}}>Disclaimer</Typography>

                        <Typography>We are not related to official servers in any sort of way.</Typography>

                        <div className="col-md-push-2 col-md-3 mt-2">
                            <div className="footer-col">
                                <h4 className="widget-title">Affiliates</h4>

                                <ul className="list-inline social-1">
                                    <li>
                                        <a href="https://www.elitepvpers.com/">ElitePVPers</a> </li>
                                    <li><a href="https://www.xtremetop100.com/in.php?site=1132375659">Xtremetop100</a> </li>
                                    <li><a href="https://discord.gg/K8uGPQDEwH">Discord</a></li>
                                    <li><a href="https://www.facebook.com/HardConquer">Facebook</a> </li>


                                </ul>
                            </div>
                        </div>
                       

                    </Grid>
                </Grid>
            </Box>
        </footer>
    )
}

export default Footer
