import React, {Component} from 'react'
import {Container, Card, Row, Col} from 'react-bootstrap'
import axios from 'axios'
import config from '../../config.json'
import IconBreadcrumbs from "../custom-breadcrumb";
import Box from "@mui/material/Box";
import {CardActions, CardContent, CardMedia, Grid} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

class Downloads extends Component {
    state = {
        mega: '#',
        googleDrive: '#',
    }
    apiEndPoint = config.apiEndPoint + 'info'

    async componentDidMount() {
        try {
            const result = await axios.get(this.apiEndPoint)
            if (result.status == 200) {
                const data = result.data.value
                this.setState({mega: data.mega, googleDrive: data.google})
            }
        } catch {
        }
    }

    render() {
        return (
            <Box>
                <IconBreadcrumbs name={"Download the game"}/>
                <Container>
                    <h3 className="text-center mb-30">DOWNLOAD NOW AND START YOUR ADVENTURE</h3>
                    <Grid style={{border: '1px solid black', borderRadius: '5px', opacity: 0.9}}
                          className="padding-top-2x padding-bottom-5x mb-2 content border-2">
                        <Grid container spacing={2}>
                            <Grid item lg={4}>
                                <p>You can download and install HardConquer using the following download link. Once
                                    downloaded, simply install and run the game from the new desktop shortcut.</p>

                                <div className="text-center">
                                    <a className="market-button windows-button"
                                        href="https://hardconquer.online/download/hardconquer1.0.rar"><span
                                        className="mb-subtitle"></span><span
                                        className="mb-title">Direct Download</span></a>
                                </div>
                                <div className="text-center">
                                    <a className="market-button windows-button"
                                        href="https://mega.nz/file/4Xtj0Ipa#3HgpkQjsdWH-U2IzK6iQ6YvDLS863FnlVWJ_sa7awuc"><span
                                        className="mb-subtitle"></span><span
                                        className="mb-title">Mega</span></a>
                                </div>
                                <div className="text-center">
                                    <a className="market-button windows-button"
                                        href="https://www.mediafire.com/file/94qkvg6cgmqlgp9/Hard_Conquer_1.0.rar/file"><span
                                        className="mb-subtitle"></span><span
                                        className="mb-title">Mediafire</span></a>
                                </div>
                                <div className="text-center">
                                    <a className="market-button windows-button"
                                        href="https://hardconquer.online/download/patch/1019.exe"><span
                                            className="mb-subtitle"></span><span
                                                className="mb-title">Latest Patch (1019)</span></a>
                                </div>
                            </Grid>
                            <Grid item lg={8}
                                  style={{visibility: 'visible', animationDuration: '1s', animationName: 'fadeInUp'}}>
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th><Typography variant={'h6'}>#</Typography></th>
                                        <th style={{textAlign: "center"}}><Typography
                                            variant={'h6'}>Minimum</Typography></th>
                                        <th style={{textAlign: "center"}}><Typography
                                            variant={'h6'}>Suggested</Typography></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td><Typography variant={'h6'}>OS</Typography>
                                        </td>
                                        <td style={{textAlign: "center"}}>Windows XP</td>
                                        <td style={{textAlign: "center"}}>Windows 10</td>
                                    </tr>
                                    <tr>
                                        <td><Typography variant={'h6'}>CPU</Typography>
                                        </td>
                                        <td style={{textAlign: "center"}}>Pentium III 800 MHz</td>
                                        <td style={{textAlign: "center"}}>Pentium 4 - 1.5 Ghz</td>
                                    </tr>
                                    <tr>
                                        <td><Typography variant={'h6'}>RAM</Typography></td>
                                        <td style={{textAlign: "center"}}>128 MBs</td>
                                        <td style={{textAlign: "center"}}>512 MBs</td>
                                    </tr>
                                    <tr>
                                        <td><Typography variant={'h6'}>GPU</Typography></td>
                                        <td style={{textAlign: "center"}}>16MB, DirectX3D,TNT2M64</td>
                                        <td style={{textAlign: "center"}}>64MB, GeForce2</td>
                                    </tr>
                                    <tr>
                                        <td><Typography variant={'h6'}>HDD</Typography></td>
                                        <td colSpan="2" style={{textAlign: "center"}}>2 Gigabytes</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

        )
    }
}

export default Downloads
