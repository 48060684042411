import conquerImage1 from '../assets/7.jpg';
import conquerImage2 from '../assets/8.jpg';

import {Grid, Box, Typography, Divider} from "@mui/material";
import {Container} from 'react-bootstrap';
import {SectionDivider} from './common/section-divider';
import {BoxItem} from "./BoxItem";

export const HomeCards = () => {
    return (
        <Container style={{marginTop: 100, textAlign: "center", color: '#fff'}}>
            <Typography sx={{mt: 5, mb: 5}} variant={'h4'} fontWeight={'bold'}>
                Start your amazing journey in one of the best classic servers out there
            </Typography>
            <Grid container spacing={4} p={5} sx={{color: "rgb(57, 171, 111)"}}>
                <Grid item lg={6} md={6} xs={12}>
                    <iframe src="https://discord.com/widget?id=1222125098715189359&theme=dark" width="350" height="500" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
                </Grid>
                <Grid item lg={6} xs={12}>
                    <iframe
                        src="https://www.facebook.com/plugins/page.php?href=https://www.facebook.com/HardConquer&tabs=timeline&width=400&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                        width="400" height="500" style={{border: "none", overflow: "hidden"}} scrolling="no"
                        frameBorder="0" allowFullScreen="true"
                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                </Grid>
            </Grid>
            <SectionDivider/>
            <Typography variant={'h5'}>Game Features</Typography>
            <Grid container spacing={2}>
                <Grid item lg={3}>
                    <BoxItem text={'Classic Version 5065'}/>
                </Grid>
                <Grid item lg={3}>
                    <BoxItem text={'No Ninja'}/>
                </Grid>
                <Grid item lg={3}>
                    <BoxItem text={'NO Battle Power'}/>
                </Grid>
                <Grid item lg={3}>
                    <BoxItem text={'Custom Events'}/>
                </Grid>
                <Grid item lg={3}>
                    <BoxItem text={'Balanced PVP'}/>
                </Grid>
                <Grid item lg={3}>
                    <BoxItem text={'Duel Bots'}/>
                </Grid>
            </Grid>
        </Container>
    )
}